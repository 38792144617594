import { extractSrcFromIframe } from "@/utils/helpers"
import { FormState } from "../.."

const Map = ({
  form
}: {
  form: FormState
}) => {

  const link = extractSrcFromIframe(form.map_link)

  return (
    <div className="w-full h-[350px] overflow-hidden">
        {link && 
        (<iframe 
        src={link}
        width="100%" 
        height="100%" 
        loading="lazy"/>)
        }
    </div>
  )
}
export default Map