import Image from "@/components/customUI/image"
import { Button } from "@/components/ui/button"
import { onlyDate } from "@/utils/helpers"
import { Calendar, EyeIcon } from "lucide-react"
import Edit from '@/assets/icon/edit.svg'
import Trash from '@/assets/icon/trash.svg'
import Icon from "@/components/customUI/icon"
import { IBlog } from "@/types"
import { API_URL } from "@/config"
import { AlertDialogComp } from "@/components/customUI/Alert"

const Blog = ({
    data,
    deleteFunc
}: {
    data: IBlog
    deleteFunc: () => void
}) => {

    const handleEdit = () => {
        window.location.href = `/blog/duzenle/${data.id}`
    };
    
  return (
    <div className="flex gap-10 group justify-between">
        <div className="flex gap-5 w-full">
            <div className="aspect-square shrink-0 w-[150px] overflow-hidden">
                <Image src={`${API_URL}/${data.image}`}/>
            </div>
            <div className="flex flex-col justify-between w-full lg:py-3">
                <p className="line-clamp-2 group-hover:text-first duration-300 font-semibold">{data.title}</p>
                <p className="text-xs font-medium text-third-dark line-clamp-4">
                    <p dangerouslySetInnerHTML={{__html: data.desc}}/>
                </p>
                <div className="flex items-center gap-5">
                    <div className="flex items-center gap-3 text-sm font-semibold text-third-dark">
                        <Calendar size={16}/>
                        <span>{onlyDate(new Date(data.createdAt))}</span>
                    </div>
                    <div className="flex items-center gap-3 text-sm font-semibold text-third-dark">
                        <EyeIcon size={16}/>
                        <span>{data.readCount}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-col gap-3 shrink-0 min-w-[150px]">
            <Button onClick={handleEdit} variant={'accept'} className='gap-3'>
                <Icon color='#fff' icon={<Edit/>}/> 
                Düzenle
            </Button>
            <AlertDialogComp
                customButton={
                    <Button variant={'reject'} className='gap-3'>
                        <Icon color='#fff' icon={<Trash/>}/> 
                        Sil
                    </Button>
                }
                handleAccept={deleteFunc}
                title="Bu yazıyı silmek istediğinize emin misiniz?"
            />
        </div>
    </div>
  )
}

export default Blog