import Heading from "@/components/customUI/Heading"
import SelectComp from "@/components/customUI/SelectComp"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import request from "@/utils/request"
import { useCallback, useEffect, useState } from "react"
import { FormState } from ".."
import SelectCompForAdres from "@/components/customUI/SelectCompForAdres"
import { IListingType, PriceType, SelectCategoriesProps } from "@/types"
import CurrencyInput from 'react-currency-input-field';
import SelectCategory from "./SelectCategory"
/* interface City {
    id: number;
    name: string;
  } */
export interface District {
    id: number;
    city_id: number;
    name: string;
  }

  export interface Neighborhoods {
    id: number;
    district_id: number;
    name: string;
  }
const Form = ({
    form,
    setForm,
    price,
    setPrice,
    setCategoryName,
    categories,
    categoryName,
    types
}: {
    setForm: React.Dispatch<React.SetStateAction<FormState>>
    form: FormState
    price: PriceType
    setPrice: React.Dispatch<React.SetStateAction<PriceType>>
    setCategoryName: React.Dispatch<React.SetStateAction<string>>
    categories: SelectCategoriesProps[],
    categoryName: string;
    types: IListingType[]
}) => {

        /* const [cities, setCities] = useState<City[]>([]) */
        const [districts, setDistricts] = useState<District[]>([])
        const [neighborhoods, setNeighborhoods] = useState<Neighborhoods[]>([])

        /* const getCities = useCallback(async () => {
        try {
            const response = await request({url: '/address/cities', method: 'get'});
            setCities(response.data);
        } catch (err) {
            console.error(err);
        }
        }, []); */

        const getDistricts = useCallback(async () => {
        if(form.city_id) {
            try {
                const response = await request({url: `/address/districts?city_id=${form.city_id}`, method: 'get'});
                setDistricts(response.data);
                } catch (err) {
                console.error(err);
                }
        }
        }, [form.city_id]);

        const getNeighborhoods = useCallback(async () => {
        if(form.district_id) {
            try {
                const response = await request({url: `/address/neighborhoods?district_id=${form.district_id}`, method: 'get'});
                setNeighborhoods(response.data);
                } catch (err) {
                console.error(err);
                }
        }
        }, [ form.district_id]);

        /* useEffect(() => {
        getCities()
        }, [getCities]) */

        useEffect(() => {
        getDistricts()
        }, [getDistricts])

        useEffect(() => {
        getNeighborhoods()
        }, [getNeighborhoods])

        useEffect(() => {
            const district = districts.find(c => c.id === parseInt(form.district_id))?.name || '';
            const neighborhood = neighborhoods.find(c => c.id === parseInt(form.neighborhoods_id))?.name || '';
        
            if ( form.district !== district || form.neighborhoods !== neighborhood) {
                setForm(prevForm => ({
                    ...prevForm,
                    neighborhoods: neighborhood,
                    district: district
                }));
            }
        }, [ districts, neighborhoods, form.district_id, form.neighborhoods_id, form.district, form.neighborhoods, setForm]);

  return (
    <div className="col-span-5 space-y-3">
        <Heading title="Temel Bilgiler"/>

        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 px-2 lg:px-3">
            <div className="space-y-1">
                <Label>İlan Adı</Label>
                <Input
                    placeholder="İlan Adı"
                    value={form.name}
                    onChange={(e) => setForm({...form, name: e.target.value})}
                />
            </div>
            <div className="space-y-1">
                <Label>Liste Fiyatı</Label>
                <CurrencyInput
                    placeholder="₺ 100.000,00"
                    decimalSeparator=","
                    groupSeparator="."
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    allowDecimals
                    prefix="₺ "
                    value={price?.value}
                    onValueChange={(_value, _name, values) => {
                        if (values) {
                          setPrice({
                            float: values.float,
                            formatted: values.formatted,
                            value: values.value
                          });
                        }
                      }}
                    className="inputClass"
                />
            </div>
        </div>

        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 px-2 lg:px-3">
            <div className="space-y-1">
                <Label>İlan Tip</Label>
                <SelectComp 
                data={types.map((v) => ({name: v.name, value: v.id.toString()}))}
                label="İlan Tipleri"
                value={form.type}
                onValueChange={(e) => setForm({...form, type: e})}
                placeholder="İlan Tip Seç"/>
            </div>

            <div className="space-y-1">
                <Label>İlan Kategorisi</Label>
                <SelectCategory
                    setCategoryName={setCategoryName}
                    categoryName={categoryName}
                    categories={categories}
                    onValueChange={(e: string) => setForm({...form, category: e})}
                    value={form.category}
                />
            </div>
        </div>

        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 px-2 lg:px-3">
            <div className="space-y-1">
                <Label>İlçe</Label>
                <SelectCompForAdres
                    data={districts}
                    onValueChange={(e) => setForm({...form, district_id: e})}
                    value={form.district_id}
                    label="İlçeler"
                    placeholder="İlçe Seçin"
                    disabled={form.city_id === ''}
                />
            </div>
            <div className="space-y-1">
                <Label>Mahalle / Semt</Label>
                <SelectCompForAdres
                        data={neighborhoods}
                        onValueChange={(e) => setForm({...form, neighborhoods_id: e})}
                        value={form.neighborhoods_id}
                        label="Mahalleler/Semtler"
                        placeholder="Mahalle/Semt Seçin"
                        disabled={form.district_id === ''}
                    />
            </div>
        </div>

        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 px-2 lg:px-3">
            <div className="space-y-1">
                <Label>Konum Linki</Label>
                <Input
                    placeholder="Konum Linki"
                    value={form.location}
                    onChange={(e) => setForm({...form, location: e.target.value})}
                />
            </div>
            <div className="space-y-1">
                <Label>Harita Linki</Label>
                <Input
                    placeholder="Harita Linki"
                    value={form.map_link}
                    onChange={(e) => setForm({...form, map_link: e.target.value})}
                />
            </div>
        </div>
    </div>
  )
}

export default Form