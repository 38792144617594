import List from '@/assets/icon/list.svg';
import AddList from '@/assets/icon/add-list.svg';
import Infos from '@/assets/icon/infos.svg';
import Properties from '@/assets/icon/properties.svg';
import Categories from '@/assets/icon/categories.svg';
import Blogs from '@/assets/icon/blogs.svg';
import Settings from '@/assets/icon/settings.svg';


const homeCardData = [
    {
      title: "İlanlar",
      href: "/ilanlar",
      icon: <List />,
    },
    {
      title: "İlan Ekle",
      href: "/ilan-ekle",
      icon: <AddList />,
    },
    {
      title: "Kategori Yönetimi",
      href: "/kategoriler",
      icon: <Categories />,
    },
    {
      title: "İlan Tipi Yönetimi",
      href: "/ilan-tipi",
      icon: <Infos />,
    },
    {
      title: "İlan Özellikleri Yönetimi",
      href: "/ozellikler",
      icon: <Properties />,
    },
    {
      title: "Blog Yönetimi",
      href: "/blog",
      icon: <Blogs />,
    },
    {
      title: "Ayarlar",
      href: "/ayarlar",
      icon: <Settings />,
    },
  ];
  
  export default homeCardData;
  