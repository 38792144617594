import MainWrapper from "@/components/customUI/MainWrapper"
import Type from "./Type"
import { IListingType } from "@/types"
import { Star } from "lucide-react"
import VariantLoading from "./VariantLoading"
import AddAndUpdateTypePopup from "./AddAndUpdateTypePopup"

const Types = ({
    types,
    selectedType,
    setSelectedType,
    getData,
    loading
}: {
    types: IListingType[]
    selectedType: IListingType | undefined
    setSelectedType: React.Dispatch<React.SetStateAction<IListingType | undefined>>
    getData: () => void,
    loading: boolean
}) => {
  return (
    <MainWrapper className="rounded-md border border-border overflow-hidden">
        <div className="w-full py-3 px-5 bg-[#e5e5e5] shrink-0 flex items-center justify-between">
            <p className="font-semibold">İlan Tipleri</p>
            <AddAndUpdateTypePopup
                getData={getData}
            />
        </div>
        <div className="h-full overflow-y-auto flex flex-col gap-3 p-5">
            {
                !loading ?
                types.length > 0 ?
                types.map((v, i) => (
                    <Type
                        key={i}
                        data={v}
                        selected={selectedType}
                        selectFunc={() => setSelectedType(v)}
                        getData={getData}
                    />
                ))
                : (
                    <div className="flex items-center justify-center flex-col w-full h-full gap-3">
                        <Star size={40}/>
                        <p className="text-sm font-semibold">İlan Tipi bulunamadı...</p>
                    </div>
                )
                :
                [...Array(10)].map((v) => (
                    <VariantLoading
                        key={v}
                        group
                    />
                ))
            }
        </div>
    </MainWrapper>
  )
}

export default Types