import { Skeleton } from "@/components/ui/skeleton"

const BlogLoading = () => {
  return (
    <div className="flex gap-10 group justify-between">
        <div className="flex gap-5 w-full">
            <div className="aspect-square shrink-0 w-[150px] overflow-hidden">
                <Skeleton className="w-full h-full"/>
            </div>
            <div className="flex flex-col justify-between w-full lg:py-3">
                <Skeleton className="w-3/4 h-6 rounded-full"/>

                <div className="space-y-1">
                <Skeleton className="w-4/4 h-3 rounded-full"/>
                <Skeleton className="w-3/4 h-3 rounded-full"/>
                <Skeleton className="w-2/4 h-3 rounded-full"/>
                <Skeleton className="w-1/4 h-3 rounded-full"/>
                </div>

                <Skeleton className="w-1/4 h-5 rounded-full"/>
            </div>
        </div>

        <div className="flex flex-col gap-3 shrink-0 min-w-[150px]">
            <Skeleton className="w-full h-10 rounded-md"/>
            <Skeleton className="w-full h-10 rounded-md"/>
        </div>
    </div>
  )
}

export default BlogLoading