import { FC } from "react"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import TextEditor from "@/components/customUI/Texteditor"
import { FormState } from "."

interface DescProps {
    setDesc: React.Dispatch<React.SetStateAction<string>>
    desc: string
    setForm: React.Dispatch<React.SetStateAction<FormState>>
    form: FormState
}

const DescSeo: FC<DescProps> = ({
    setDesc,
    desc,
    form,
    setForm
}) => {

  return (
    <div className="space-y-3 lg:space-y-5 lg:px-10">
        <div className="space-y-2">
            <Label>Kısa Açıklama (Max 160 Karakter)*</Label>
            <Textarea
            className="resize-none"
            value={form.short_desc}
            maxLength={160}
            onChange={(e) => setForm({...form, short_desc: e.target.value})}
            />
        </div>
        <div className="space-y-2">
            <Label>Açıklama*</Label>
            <div className="w-full h-[450px]">
                <TextEditor
                    setDesc={setDesc}
                    desc={desc}
                />
            </div>
        </div>
    </div>
  )
}

export default DescSeo