
import Image from "@/components/customUI/image";
import { API_URL } from "@/config";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Slider from "react-slick";

interface ArrowButtonProps {
    onClick: () => void;
}

const Images = ({
    images,
}: {
    images: string[];
}) => {
    const [selectedImage, setSelectedImage] = useState(0)

    const PrevBtn : React.FC<ArrowButtonProps> = ({onClick}) => {
    return (
        <button 
            onClick={onClick} 
            className="absolute top-0 !left-0 h-full w-[35px] shrink-0 bg-first hover:bg-primary hover:text-first duration-300 text-primary flex items-center justify-center">
            <BiChevronLeft size={28}/>
            </button>
    );
    };
    const NextBtn: React.FC<ArrowButtonProps> = ({onClick}) => {
    return (
        <button 
        onClick={onClick} 
        className="absolute top-0 !right-0 h-full w-[35px] shrink-0 bg-first hover:bg-primary hover:text-first duration-300 text-primary flex items-center justify-center">
            <BiChevronRight size={28}/>
        </button>
    );
    };

    const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    className: 'slider-size animate-fade slider-padding',
    nextArrow: <NextBtn onClick={() => {}}/>,
    prevArrow: <PrevBtn onClick={() =>  {}}/>,
    responsive: [
        {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
        }
        },
        {
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
        }
        }
    ]
    };

    const getImageSrc = (image: string) => {
        return image.includes("uploads/list/") ? `${API_URL}/${image}` : image;
    };

  return (
    <div className="space-y-5">
        <div className="w-full aspect-[940/510] overflow-hidden border border-border rounded-xl">
            <Image
                className="w-full h-full object-contain"
                src={getImageSrc(images[selectedImage])}
                existSrcSet={false}
            />
        </div>
        {images && images?.length > 2 ? 
        <div className="w-full aspect-[5/1] lg:aspect-[7/1] overflow-hidden">
        <Slider {...settings}>
            {
            images?.map((v, i) => (
                <div key={i} 
                onClick={() => setSelectedImage(i)}
                className={cn(`px-2 flex h-full duration-300 cursor-pointer rounded-xl overflow-hidden border`, 
                i === selectedImage ? 'border-first opacity-100' : 'border-transparent opacity-80')}>
                <Image
                    src={getImageSrc(v)}
                    existSrcSet={false}
                />
                </div>
            ))
            }
        </Slider>
        </div> : null}
    </div>
  )
}

export default Images