/* eslint-disable @typescript-eslint/no-explicit-any */
import request from "@/utils/request";
import { FormState } from ".";
import { IFeatureForListing, IListingInfo } from "@/types";

export const createListing = async({
    images,
    form,
    price,
    desc,
    props,
    infos,
}: {
 images: any[],
 form: FormState
 price: number,
 desc: string
 props: IFeatureForListing[]
 infos: IListingInfo[]
}) => {
    try {
        const formData = new FormData();

        images.forEach((img) => {
        if (img) {
            formData.append(`images`, img);
        }
        });

        formData.append('name', form.name);
        formData.append('category', form.category);
        formData.append('price', price.toString());
        formData.append('desc', desc);
        formData.append('short_desc', form.short_desc);
        formData.append('city', form.city);
        formData.append('city_id', form.city_id);
        formData.append('district', form.district);
        formData.append('district_id', form.district_id);
        formData.append('neighborhoods', form.neighborhoods);
        formData.append('neighborhoods_id', form.neighborhoods_id);
        formData.append('location', form.location);
        formData.append('map_link', form.map_link);
        formData.append('type', form.type);
        formData.append('props', JSON.stringify(props));
        formData.append('infos', JSON.stringify(infos));

        const res = await request({url: '/listing/create', data: formData, customHeaders: {
            'Content-Type': 'multipart/form-data',
          }})
            return res?.data
    } catch (error: any) {
        throw new Error(error);
    }
}

export const updateListing = async ({
    id,
    images,
    form,
    price,
    desc,
    props,
    infos,
  }: {
    id: string,
    images: any[],
    form: FormState,
    price: number,
    desc: string,
    props: IFeatureForListing[],
    infos: IListingInfo[],
  }) => {
    try {
      const hasValidImages = images.some(img => img && img !== '');
  
      if (hasValidImages) {
        const formData = new FormData();
  
        images.forEach((img) => {
          if (img) {
            formData.append('images', img);
          }
        });

        formData.append('name', form.name);
        formData.append('category', form.category);
        formData.append('price', price.toString());
        formData.append('desc', desc);
        formData.append('short_desc', form.short_desc);
        formData.append('city', form.city);
        formData.append('city_id', form.city_id);
        formData.append('district', form.district);
        formData.append('district_id', form.district_id);
        formData.append('neighborhoods', form.neighborhoods);
        formData.append('neighborhoods_id', form.neighborhoods_id);
        formData.append('location', form.location);
        formData.append('map_link', form.map_link);
        formData.append('type', form.type);
        formData.append('props', JSON.stringify(props));
        formData.append('infos', JSON.stringify(infos));
  
        const res = await request({
          url: `/listing/update?id=${id}`,
          data: formData,
          customHeaders: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        return res?.data;
      } else {
        const body = {
          name: form.name,
          category: form.category,
          price,
          desc,
          short_desc: form.short_desc,
          city: form.city,
          city_id: form.city_id,
          district: form.district,
          district_id: form.district_id,
          neighborhoods: form.neighborhoods,
          neighborhoods_id: form.neighborhoods_id,
          location: form.location,
          map_link: form.map_link,
          type: form.type,
          props: JSON.stringify(props),
          infos: JSON.stringify(infos),
        };
  
        const res = await request({
          url: `/listing/update?id=${id}`,
          data: body,
        });
  
        return res?.data;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };
  