import { onlyDate, priceMasking } from "@/utils/helpers"
import Home from '@/assets/icon/home.svg'
import { Bookmark, Calendar, List, LocateIcon, Phone, Share2Icon } from "lucide-react"
import { FaWhatsapp } from "react-icons/fa"
import Icon from "@/components/customUI/icon"
import { FormState } from ".."
import { IListingInfo, IListingType, PriceType } from "@/types"
import Image from "@/components/customUI/image"
import { API_URL } from "@/config"

const Aside = ({
    form,
    price,
    infos,
    types,
    createdAt
}: {
    form: FormState
    price: PriceType
    infos: IListingInfo[]
    types: IListingType[]
    createdAt: Date | undefined
}) => {
  return (
    <aside className="col-span-12 lg:col-span-3 space-y-5 p-3 lg:p-5 border-border lg:border rounded-xl h-fit">
        <p className="text-lg font-semibold lg:text-xl">{priceMasking(price.float || 0)}</p>

        <div className="flex items-center gap-3 flex-wrap text-xs font-semibold text-third-dark">
            <div className="flex items-center gap-2">
                <Icon
                    icon={<Home/>}
                    color="#A9B0B9"
                    width="16px"
                />
                <p>{types.find((v) => v.id.toString() === form.type)?.name}</p>
            </div>
            {infos.map((v, i) => (
                v.typeValue.preview && (
                    <div key={i} className="flex items-center gap-2">
                        <div className="w-4 h-4 shrink-0">
                            <Image
                                src={`${API_URL}/${v.typeValue.icon}`}
                                alt={v.typeValue.name} // Alternatif metin eklemek iyi bir uygulamadır.
                            />
                        </div>
                        <p>
                            {v.typeValue.name.toLowerCase().includes("metrekare") 
                                ? `${v.value} m²` 
                                : v.value}
                        </p>
                    </div>
                )
            ))}
        </div>

        <p className="text-xs font-semibold text-third-dark">{form.neighborhoods} / {form.district}</p>

        <div className="flex items-center gap-5 flex-wrap text-xs text-third-dark">
            <p className="flex items-center gap-1"><List size={16}/> <span>İlan No: <b>13254</b></span></p>
            {createdAt ? (
                <p className="flex items-center gap-1"><Calendar size={16}/> <b>{onlyDate(new Date(createdAt))}</b></p>
            ) :
            (
                <p className="flex items-center gap-1"><Calendar size={16}/> <b>{onlyDate(new Date())}</b></p>
            )}
        </div>

        <div className="space-y-2">
            <button 
            className="w-full h-10 bg-first text-primary text-sm font-semibold flex items-center justify-center gap-3 rounded-xl hover:bg-opacity-80 duration-300">
                <LocateIcon size={24}/>
                <span>Konuma Git</span>
            </button>
            <button 
            className="w-full h-10 bg-primary text-first text-sm font-semibold flex items-center justify-center gap-3 rounded-xl hover:bg-opacity-70 duration-300">
                <Phone size={24}/>
                <span>Ara</span>
            </button>
            <button 
            className="w-full h-10 bg-green-500 text-white text-sm font-semibold flex items-center justify-center gap-3 rounded-xl hover:bg-opacity-80 duration-300">
                <FaWhatsapp size={24}/>
                <span>WhatsApp</span>
            </button>
        </div>

        <div className="space-y-3 text-sm font-semibold">
            <button className="flex w-full items-center justify-between text-primary hover:text-first duration-300">
                <p>İlanı Paylaş</p>
                <Share2Icon size={24}/>
            </button>
            <button className="flex items-center w-full border-t pt-3 justify-between text-primary hover:text-first duration-300">
                <p>İlanı Kaydet</p>
                <Bookmark size={24}/>
            </button>
        </div>
    </aside>
  )
}

export default Aside