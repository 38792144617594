/* eslint-disable @typescript-eslint/no-explicit-any */
import MainWrapper from "@/components/customUI/MainWrapper"
import { Button } from "@/components/ui/button"
import Blog from "./Blog"
import { useCallback, useEffect, useState } from "react";
import { IBlog } from "@/types";
import request from "@/utils/request";
import { PaginationComp } from "@/components/customUI/PaginationComp";
import { useToast } from "@/hooks/use-toast";
import { SearchX } from "lucide-react";
import BlogLoading from "./BlogLoading";

const BlogPage = () => {

  const handleEdit = () => {
    window.location.href = `/blog/ekle`
  };

  const [data, setData] = useState<IBlog[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1)
  const {toast} = useToast()
  const handleGetData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await request({url: `/blogs/admin/getAll?page=${page}&limit=10`, method: 'get'})
      setData(res.data.blogs)
      setTotal(res.data.total)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      setLoading(false)
    }
  }, [page])

  useEffect(() => {
    handleGetData()
  }, [handleGetData])
  
  const handleDelete = async(id: number) => {
    try {
      const res = await request({url: `/blogs/delete?id=${id}`})
      toast({title: 'İşlem Başarılı.', description: res.data.message})
      handleGetData()
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return (
    <MainWrapper>
        <div className="flex items-center gap-5 justify-between py-3 border-b border-border px-5">
            <p className="text-sm">Toplam Yazı: <b>{total}</b></p>

            <Button onClick={handleEdit} variant={'first'}>Ekle</Button>
        </div>

        <div className="flex flex-col gap-5 px-5 w-full h-full overflow-y-auto">
            {
              !loading ?
              data.length > 0 ?
              data.map((v, i) => (
                <Blog key={i} data={v} deleteFunc={() => handleDelete(v.id)}/>
              ))
              :
              <div className="flex items-center justify-center flex-col gap-3 w-full h-full text-lg font-semibold text-[#A9B0B9]">
                <SearchX size={30}/>
                <p>Sonuç bulunamadı...</p>
              </div>
              :
              [...Array(5)].map((_, i) => (
                <BlogLoading key={i}/>
              ))
            }
        </div>
        {
          totalPages > 0 && 
          (
            <PaginationComp
              setPage={setPage}
              currentPage={page}
              totalPages={totalPages}
            />
          )
        }
    </MainWrapper>
  )
}

export default BlogPage