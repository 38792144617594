/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react"
import Types from "./Types"
import TypeValues from "./TypeValues"
import request from "@/utils/request"
import { IListingType, IListingTypeValue } from "@/types"

const ListTypePage = () => {

    const [types, setTypes] = useState<IListingType[]>([])
    const [selectedType, setSelectedType] = useState<IListingType>()
    const [typesLoading, setTypesLoading] = useState(true)
    const [valuesLoading, setValueLoading] = useState(true)
    const [values, setValues] = useState<IListingTypeValue[]>([])
    const getTypes = useCallback(async() => {
        try {
            setTypesLoading(true)
            const res = await request({url: '/info/types', method: 'get'})
            setTypes(res.data)
        } catch (error:any) {
            throw new Error(error)
        } finally {
            setTypesLoading(false)
        }
    }, [])

    useEffect(() => {
        getTypes()
    }, [getTypes])

    useEffect(() => {
      if(types.length > 0) {
        setSelectedType(types[0])
      }
    }, [types])

    const getValues = useCallback(async() => {
        try {
            setValueLoading(true)
            if(selectedType) {
                const res = await request({url: `/info/by-type?id=${selectedType.id}`, method: 'get'})
                setValues(res.data)
            }
        } catch (error:any) {
            throw new Error(error)
        } finally {
            setValueLoading(false)
        }
    }, [selectedType])

    useEffect(() => {
        getValues()
    }, [getValues])

  return (
    <div className="grid grid-cols-2 gap-5 lg:px-5 h-full">
        <Types
        types={types}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        getData={getTypes}
        loading={typesLoading}
        />
        <TypeValues
            values={values}
            getData={getValues}
            loading={valuesLoading}
            group_id={selectedType?.id}
        />
    </div>
  )
}

export default ListTypePage