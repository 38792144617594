/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react"
import Groups from "./Groups"
import { IFeature, IFeatureValue } from "@/types"
import request from "@/utils/request"
import Options from "./Options"

const Properties = () => {

    const [groups, setGroups] = useState<IFeature[]>([])
    const [selectedGroups, setSelectedGroups] = useState<IFeature>()
    const [groupLoading, setGroupLoading] = useState(true)
    const [optionLoading, setOptionLoading] = useState(true)
    const getGroups = useCallback(async() => {
        try {
            setGroupLoading(true)
            const res = await request({url: '/feature/all', method: 'get'})
            setGroups(res.data)
        } catch (error:any) {
            throw new Error(error)
        } finally {
            setGroupLoading(false)
        }
    }, [])

    useEffect(() => {
        getGroups()
    }, [getGroups])

    useEffect(() => {
      if(groups.length > 0) {
        setSelectedGroups(groups[0])
      }
    }, [groups])

    const [options, setOptions] = useState<IFeatureValue[]>([])

    const getOptions = useCallback(async() => {
        try {
            setOptionLoading(true)
            if(selectedGroups) {
                const res = await request({url: `/feature/allValue?id=${selectedGroups.id}`, method: 'get'})
                setOptions(res.data)
            }
        } catch (error:any) {
            throw new Error(error)
        } finally {
            setOptionLoading(false)
        }
    }, [selectedGroups])

    useEffect(() => {
        getOptions()
    }, [getOptions])

  return (
    <div className="grid grid-cols-2 gap-5 lg:px-5 h-full">
        <Groups
                groups={groups}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                getData={getGroups}
                loading={groupLoading}
            />
            <Options
                options={options}
                getData={getOptions}
                loading={optionLoading}
                group_id={selectedGroups?.id}
            />
    </div>
  )
}

export default Properties