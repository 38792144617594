/* eslint-disable @typescript-eslint/no-explicit-any */
import { IListingType, PriceType, SelectCategoriesProps } from "@/types"
import { FormState } from ".."
import Form from "./Form"
import ImageWrapper from "./Images"

const BaseInfo = ({
    setImagesPre,
    setImages,
    images,
    imagesPre,
    setForm,
    form,
    price,
    setPrice,
    setCategoryName,
    categories,
    categoryName,
    types,
    id
}: {
    setImages: React.Dispatch<React.SetStateAction<any[]>>
    setImagesPre: React.Dispatch<React.SetStateAction<string[]>>
    images: any[]
    imagesPre: string[]
    setForm: React.Dispatch<React.SetStateAction<FormState>>
    form: FormState
    price: PriceType
    setPrice: React.Dispatch<React.SetStateAction<PriceType>>
    setCategoryName: React.Dispatch<React.SetStateAction<string>>
    categories: SelectCategoriesProps[]
    categoryName: string
    types: IListingType[]
    id: string | null
}) => {
  return (
    <div className="grid-cols-5 grid place-content-start gap-5">
        <ImageWrapper
            setImages={setImages}
            setImagesPre={setImagesPre}
            images={images}
            imagesPre={imagesPre}
            id={id}
        />
        <Form
            form={form}
            setForm={setForm}
            price={price}
            setPrice={setPrice}
            setCategoryName={setCategoryName}
            categories={categories}
            categoryName={categoryName}
            types={types}
        />
    </div>
  )
}

export default BaseInfo