/* eslint-disable @typescript-eslint/no-explicit-any */
import Image from "@/components/customUI/image";
import { AlertDialogFooter } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { API_URL } from "@/config";
import { useToast } from "@/hooks/use-toast";
import { useFileHandler } from "@/hooks/useFileHandler";
import { IListingTypeValue } from "@/types";
import request from "@/utils/request";
import { ImageIcon } from "lucide-react";
import { ChangeEvent, FC, useEffect, useState } from "react"

interface TypeValueProps {
    data?: IListingTypeValue;
    getData?: () => void;
    typeId: number;
  }

const TypeValuePopup: FC<TypeValueProps> = ({
    data,
    getData,
    typeId
  }) => {

    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [preview, setPreview] = useState(false)
    const { handleSelectFile, image, setImage } = useFileHandler();
    const {toast} = useToast()
    const handleSingleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSelectFile(e, (file) => setImage({ data: file, pre: null }), (preview) => setImage((prev) => ({ ...prev, pre: preview })));
      };

      const handleSave = async () => {
        try {
          setLoading(true);
      
          let body: any;
          const config: any = {
            url: data ? `/info/typeValue/update` : '/info/typeValue/create',
          };
      
          if (image.data) {
            body = new FormData();
            body.append('name', name);
            body.append('preview', preview ? '1' : '0');
            body.append('typeId', typeId);
            body.append('icon', image.data);
            if(data) {
                body.append('id', data.id);
            }
            config.headers = { 'Content-Type': 'multipart/form-data' };
          } else {
            if(data) {
                body = {
                    name,
                    typeId,
                    preview: preview ? '1' : '0',
                    id: data.id,
                  };
            } else {
                body = {
                    name,
                    typeId,
                    preview: preview ? '1' : '0'
                };
            }
            config.headers = { 'Content-Type': 'application/json' };
          }
      
          if(name) {
            config.data = body;
          const res = await request(config);
          if(res.data.error) {
            return toast({title: 'İşlem Başarısız!', description: res.data.message})
          }
          toast({title: 'İşlem Başarılı', description: res.data.message})
          if(getData) {
            getData()
          } 
          setOpen(false); 
          setName('')
          setPreview(false)
          setImage({data: null, pre: null})
          } else {
            toast({title: 'İşlem Başarısız!', description: "İlan tipi değer adı alanı zorunludur."})
          }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error: any) {
            toast({title: 'İşlem Başarısız!'})
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        if(data) {
            setName(data.name);
            setPreview(data.preview)
        }
      }, [data])
  return (
    <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
        {data ?
            <Button variant={'accept'}>Düzenle</Button>
            : <Button variant="accept">Ekle</Button>
        }
        </DialogTrigger>
        <DialogContent className="sm:max-w-[550px]">
        <DialogHeader>
            <DialogTitle>İlan Tipi Değeri {data ? 'Düzenle' : 'Ekle'}</DialogTitle>
            <DialogDescription className="text-xs text-primary/80">
                İkon alanı, eğer değeriniz ilan ön izlemesinde yer alacaksa girilmesi tavsiye edilir.
            </DialogDescription>
        </DialogHeader>
            <div className="flex flex-col gap-5 items-center justify-center py-2">
            <div className="w-[100px] aspect-square shrink-0 rounded-md overflow-hidden border border-border relative flex items-center justify-center">
                    {
                        image.pre ? (
                            <Image 
                            alt="image" 
                            existSrcSet={false}
                            className="object-contain w-full h-full"
                            src={image.pre as string}/>) 
                            : 
                            data?.icon ? (
                                <Image
                                alt="icon"
                                className="object-contain w-full h-full"
                                src={`${API_URL}/${data.icon}`}/>) 
                                :
                            (<div className="flex flex-col items-center justify-center text-[#A9B0B9]">
                                <ImageIcon  size={25}/>
                                <p className="text-xs font-semibold">İkon</p>
                            </div>)
                    }

                    <input 
                    accept="image/*" 
                    type="file" 
                    className="absolute inset-0 opacity-0" onChange={handleSingleFileChange} />
                </div>

                <div className="w-full space-y-1">
                    <Label>İsim</Label>
                    <Input
                        placeholder="Örn. Oda Sayısı"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className="w-full items-center flex gap-3 justify-between">
                    <Label htmlFor="preview">İlan Ön İzlemesinde Göster</Label>
                    <Switch checked={preview} onClick={() => setPreview(!preview)} id="preview" />
                </div> 
            </div>
            <AlertDialogFooter>
                <Button onClick={handleSave} disabled={loading} type="submit">{data ? 'Güncelle' : 'Oluştur'}</Button>
            </AlertDialogFooter>
        </DialogContent>
    </Dialog>
  )
}

export default TypeValuePopup