import { IFeatureForListing, IListingInfo } from "@/types"
import Props from "./Props"
import InfosWrapper from "./Infos"

const ProperiesWrapper = ({
    setProps,
    props,
    infos,
    setInfos,
  }: {
      setProps: React.Dispatch<React.SetStateAction<IFeatureForListing[]>>
      props: IFeatureForListing[]
      infos: IListingInfo[]
      setInfos: React.Dispatch<React.SetStateAction<IListingInfo[]>>
      id: string | null
  }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:px-5 h-full">
        <Props
            props={props}
            setProps={setProps}
        />
        <InfosWrapper
            infos={infos}
            setInfos={setInfos}
        />
    </div>
  )
}

export default ProperiesWrapper