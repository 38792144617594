import { IFeatureForListing, IListingInfo, IListingType, PriceType } from "@/types"
import { FormState } from ".."
import Aside from "./Aside"
import Images from "./Images"
import Tabs from "./Tabs"

const Preview = ({
    desc,
    form,
    imagesPre,
    price,
    infos,
    props,
    types,
    createdAt,
}: {
    desc: string
    form: FormState
    imagesPre: string[]
    price: PriceType
    props: IFeatureForListing[]
    infos: IListingInfo[]
    types: IListingType[]
    createdAt: Date | undefined
}) => {

  return (
    <main className="space-y-5 p-3 lg:p-5">
        <div className="grid grid-cols-12 gap-5">
            <section className="col-span-12 lg:col-span-9 space-y-5">
                <h1 className="text-sm font-semibold lg:text-lg line-clamp-1">{form.name}</h1>
                <Images images={imagesPre}/>
            </section>

            <Aside
                price={price}
                infos={infos}
                form={form}
                types={types}
                createdAt={createdAt}
            />

            <Tabs
                infos={infos}
                form={form}
                props={props}
                desc={desc}
            />
        </div>
    </main>
  )
}

export default Preview