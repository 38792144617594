/* eslint-disable @typescript-eslint/no-explicit-any */
import Heading from "@/components/customUI/Heading"
import MainWrapper from "@/components/customUI/MainWrapper"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useToast } from "@/hooks/use-toast"
import { ISettings } from "@/types"
import { extractSrcFromIframe } from "@/utils/helpers"
import request from "@/utils/request"
import { useCallback, useEffect, useState } from "react"

const Settings = () => {

    const [data, setData] = useState<ISettings>({
        location: '',
        mail: '',
        phone: '',
        whatsApp: '',
        facebook: '',
        instagram: '',
        id: 1
    })

    const [link, setLink] = useState('')

    const handleGetData = useCallback(async() => {
        try {
            const res = await request({url: '/settings/get', method: 'get'})
            setData(res.data.settings);
        } catch (error: any) {
            throw new Error(error)
        }
    }, [])

    useEffect(() => {
        handleGetData()
    }, [handleGetData])
    
    useEffect(() => {
        if(data.location !== '') {
            const map = extractSrcFromIframe(data.location)
            if(map) {
                setLink(map)
            }
        }
    }, [data.location])

    const {toast} = useToast()

    const handleUpdate = async() => {
        try {
            const res = await request({url: '/settings/update', data: data})
            toast({
                title: 'İşlem Başarılı.',
                description: res.data.message
            })
            handleGetData()
        } catch (error: any) {
            throw new Error(error)
        }
    }

  return (
    <MainWrapper>
        <div className="flex items-center justify-between">
            <Heading title="Ayarlar"/>
            <Button className="lg:hidden" onClick={handleUpdate}>Güncelle</Button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:p-5 w-full h-full overflow-y-auto">
            <div className="space-y-1">
                <Label>Mail Adresi</Label>
                <Input
                    placeholder="ornek@mail.com"
                    value={data.mail}
                    onChange={(e) => setData({...data, mail: e.target.value})}
                />
            </div>
            <div className="space-y-1">
                <Label>Telefon Numarası</Label>
                <Input
                    placeholder="0555 555 55 55"
                    value={data.phone}
                    onChange={(e) => setData({...data, phone: e.target.value})}
                />
            </div>
            <div className="space-y-1">
                <Label>WhatsApp Numarası</Label>
                <Input
                    placeholder="0555 555 55 55"
                    value={data.whatsApp}
                    onChange={(e) => setData({...data, whatsApp: e.target.value})}
                />
            </div>
            <div className="space-y-1">
                <Label>Facebok Adresi</Label>
                <Input
                    placeholder="https://facebook.com/dijitalyanki"
                    value={data.facebook}
                    onChange={(e) => setData({...data, facebook: e.target.value})}
                />
            </div>
            <div className="space-y-1">
                <Label>Instagram Adresi</Label>
                <Input
                    placeholder="https://instagram.com/dijitalyanki"
                    value={data.instagram}
                    onChange={(e) => setData({...data, instagram: e.target.value})}
                />
            </div>
            <div className="space-y-1">
                <Label>Harita Link</Label>
                <Input
                    value={data.location}
                    onChange={(e) => setData({...data, location: e.target.value})}
                    placeholder={`<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3020.5678644567365!2d30.3587127764167!3d40.79351353262081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409df7434ab3e7b1%3A0xe268882e940abd79!2zR8OcTkRPxJ5EVSAmU0FCQU4gR0FZUsSwTUVOS1VM!5e0!3m2!1str!2str!4v1728419237616!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`}
                />
            </div>
        </div>

        <div className="flex justify-end max-lg:hidden">
            <Button onClick={handleUpdate}>Güncelle</Button>
        </div>

        {link &&
        (<div className="w-full h-[100px] lg:h-[320px]">
            <iframe 
            src={link}
            width="100%" 
            height="100%" 
            loading="lazy"/>
        </div>)
        }
    </MainWrapper>
  )
}

export default Settings