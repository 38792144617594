/* eslint-disable @typescript-eslint/no-explicit-any */
import Heading from "@/components/customUI/Heading"
import Image from "@/components/customUI/image"
import MainWrapper from "@/components/customUI/MainWrapper"
import TextEditor from "@/components/customUI/Texteditor"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { API_URL } from "@/config"
import { useToast } from "@/hooks/use-toast"
import { useFileHandler } from "@/hooks/useFileHandler"
import { IBlog } from "@/types"
import request from "@/utils/request"
import { ImageIcon } from "lucide-react"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const AddBlog = () => {

    const { id } = useParams<{ id: string }>();

    console.log(id);

    const {toast} = useToast()
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [data, setData] = useState<IBlog>()
    const { handleSelectFile, image, setImage } = useFileHandler();
    const handleSingleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSelectFile(e, (file) => setImage({ data: file, pre: null }), (preview) => setImage((prev) => ({ ...prev, pre: preview })));
    };

    const handleCreate = async() => {
        try {
            if(!image.data && !title && !desc) {
                return toast({title: 'İşlem başarısız.', description: "Tüm alanlar zorunludur!"});
            }
            const formData = new FormData();
            formData.append('title', title);
            formData.append('desc', desc);
            if(image.data) {
                formData.append('image', image.data);
            }
            const res = await request({url: '/blogs/create', data: formData});
            toast({title: 'İşlem başarılı.', description: res.data.message});
            window.location.href = '/blog'
        } catch (error:any) {
            throw new Error(error);
        }
    }

    const handleUpdate = async () => {
        try {
            const hasValidImage = image && image.data;
    
            if (hasValidImage) {
                const formData = new FormData();
                formData.append('title', title);
                formData.append('desc', desc);
                if(id) {
                    formData.append('id', id);
                }
                if(image.data) {
                    formData.append('image', image.data);
                }
    
                const res = await request({
                    url: '/blogs/update',
                    method: 'POST',
                    data: formData,
                    customHeaders: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                toast({ title: 'İşlem başarılı.', description: res.data.message });
                window.location.href = '/blog';
            } else {
                const body = {
                    title: title,
                    desc: desc,
                    id
                };
    
                const res = await request({
                    url: '/blogs/update',
                    method: 'POST',
                    data: body,
                    customHeaders: {
                        'Content-Type': 'application/json',
                    },
                });
    
                toast({ title: 'İşlem başarılı.', description: res.data.message });
                window.location.href = '/blog';
            }
        } catch (error: any) {
            console.error(error);
            toast({ title: 'İşlem başarısız.', description: 'Bir hata oluştu.' });
        }
    };
    
    

    const handleGetData = useCallback(async () => {
        if(id) {
            try {
                const res = await request({url: `/blogs/admin/byId?id=${id}`, method: 'get'});
                setData(res.data.data);
                setTitle(res.data.data.title);
                setDesc(res.data.data.desc);
            } catch (error: any) {
                throw new Error(error);
            }
        }
    }, [id])

    useEffect(() => {
        handleGetData()
    }, [handleGetData])
    

  return (
    <MainWrapper>
        <Heading title="Yazı Ekle"/>
        <div className="grid grid-cols-6 gap-5 overflow-y-auto w-full p-8 h-[calc(100%_-_0px)]">
            <div className="col-span-2 space-y-5">
                <div className="space-y-1">
                    <Label>Yazı Başlığı</Label>
                    <Input
                        placeholder="Yazı Başlığı"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>

                <div className="space-y-1 w-full">
                    <Label>Yazı Resmi</Label>

                    <div className="w-full aspect-[1.75/1] relative border rounded-md overflow-hidden border-border">
                        {
                            image.pre ? (
                                <Image
                                alt="image" 
                                existSrcSet={false}
                                src={image.pre as string}/>) 
                                : 
                                data?.image ? (
                                    <Image
                                    alt="image"
                                    src={`${API_URL}/${data.image}`}/>) 
                                    :
                                (<div className="flex flex-col gap-3 w-full h-full items-center justify-center">
                                    <ImageIcon size={40}/>
                                </div>)
                        }

                        <input 
                        accept="image/*" 
                        type="file" 
                        className="absolute inset-0 opacity-0 cursor-pointer" onChange={handleSingleFileChange} />
                    </div>
                </div>
            </div>
            <div className="col-span-4 space-y-1 max-h-[600px]">
                <Label>Yazı</Label>
                <div className="w-full h-full">
                    <TextEditor
                        desc={desc}
                        setDesc={setDesc}
                    />
                </div>
            </div>
        </div>
        <div className="flex justify-end px-8">
            {
                id ?
                (
                    <Button onClick={handleUpdate}>Güncelle</Button>
                )
                : 
                (
                    <Button onClick={handleCreate}>Kaydet</Button>
                )
            }
        </div>
    </MainWrapper>
  )
}

export default AddBlog