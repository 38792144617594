/* eslint-disable @typescript-eslint/no-explicit-any */
import MainWrapper from "@/components/customUI/MainWrapper"
import Stage from "./Stage"
import { useCallback, useEffect, useState } from "react"
import BaseInfo from "./BaseInfo"
import { Button } from "@/components/ui/button"
import { IFeatureForListing, IListingInfo, IListingType, IListingTypeValue, PriceType, SelectCategoriesProps } from "@/types"
import request from "@/utils/request"
import DescSeo from "./DescSeo"
import ProperiesWrapper from "./Properties"
import Preview from "./Preview"
import { useToast } from "@/hooks/use-toast"
import { createListing, updateListing } from "./request"
import ListLoading from "./ProductLoading"
import { useLocation } from "react-router-dom"

export type FormState = {
    city_id: string;
    city: string;
    district: string;
    district_id: string;
    neighborhoods: string;
    neighborhoods_id: string;
    map_link: string;
    location: string;
    category: string;
    short_desc: string;
    type: string;
    name: string;
  };

const AddListPage = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    const [loading, setLoading] = useState(false)
    const [stage, setStage] = useState(1)
    const [images, setImages] = useState<any[]>([])
    const [imagesPre, setImagesPre] = useState<string[]>([])
    const [props, setProps] = useState<IFeatureForListing[]>([])
    const [desc, setDesc] = useState<string>('')
    const [infos, setInfos] = useState<IListingInfo[]>([])
    const [createdAt, setCreatedAt] = useState<Date | undefined>()
    const [price, setPrice] = useState<PriceType>({
        float: null,
        formatted: '',
        value: ''
      })
    const [form, setForm] = useState<FormState>({
        city_id: '54',
        city: 'Sakarya',            
        district: '',     
        district_id: '',
        neighborhoods: '',
        neighborhoods_id: '',
        map_link: '',
        location: '',
        category: '',
        short_desc: '',
        type: '',
        name: '',
      });
      const [categories, setCategories] = useState<SelectCategoriesProps[]>([])
      const [categoryName, setCategoryName] = useState('')
      const {toast} = useToast()
      const handleCategories = useCallback(async () => {
            try {
            const res = await request({url: `category/select-all?name=${categoryName}`, method: 'get'})
            setCategories(res.data)
            } catch (err: any) {
            throw new Error(err)
            }
        }, [categoryName])

    useEffect(() => {
        handleCategories()
    }, [handleCategories])

    const [types, setTypes] = useState<IListingType[]>([])
    const getTypes = useCallback(async() => {
        try {
            const res = await request({url: '/info/types', method: 'get'})
            setTypes(res.data)
        } catch (error:any) {
            throw new Error(error)
        }
    }, [])

    useEffect(() => {
        getTypes()
    }, [getTypes])


    const getValues = useCallback(async() => {
        try {
            if(form.type && !id) {
                const res = await request({url: `/info/by-type?id=${form.type}`, method: 'get'})
                setInfos(res.data.map((v: IListingTypeValue) => ({typeValue: v, value: '',})))
            }
        } catch (error:any) {
            throw new Error(error)
        }
    }, [form.type, id])

    useEffect(() => {
        getValues()
    }, [getValues])

    const handleStage = async() => {
        if(stage < 4) {
            setStage(stage +1 )
        } else if (stage === 4) {
            if(id) {
                try {
                    if(price.float) {
                        setLoading(true)
                        const res = await updateListing({
                            id,
                            desc,
                            form,
                            images,
                            infos,
                            props,
                            price: price.float
                        })
                        toast({title: res.message})
                        setImages([])
                        setImagesPre([])
                        window.location.reload()
                      }
                  }
                catch (error) {
                    const err = error as { status: number, response: { data: { message: string } } };
                    toast({
                    title: "İşlem Başarısız!",
                    description: err.response.data.message
                    })
                } finally {
                    setLoading(false)
                }
            } else {
                try {
                    if(price.float) {
                        setLoading(true)
                        const res = await createListing({
                          desc,
                          form,
                          images,
                          infos,
                          props,
                          price: price.float
                        })
                        toast({title: res.message})
                        setImages([])
                        setImagesPre([])
                        window.location.reload()
                      }
                  }
                catch (error) {
                    const err = error as { status: number, response: { data: { message: string } } };
                    toast({
                    title: "İşlem Başarısız!",
                    description: err.response.data.message
                    })
                } finally {
                    setLoading(false)
                }
            }
    }
}

    const handleStageBack = () => {
        if(stage !== 1) {
            setStage(stage - 1)
        }
    }

    const [isDisabled, setIsDisabled] = useState(true)

  const checkFieldsAndSetDisabled = useCallback(() => {
    if(stage === 1) {
      if(!form.name || !form.category || imagesPre.length === 0 || !form.district_id || !form.neighborhoods_id || !form.type || !form.location || !form.map_link || !price.float) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    } else if (stage === 2) {
      if(!form.short_desc || !desc) return setIsDisabled(true)
        else return setIsDisabled(false)
    }
  }, [desc, form.category, form.district_id, form.location, form.map_link, form.name, form.neighborhoods_id, form.short_desc, form.type, imagesPre.length, price.float, stage])

  useEffect(() => {
    checkFieldsAndSetDisabled()
  }, [checkFieldsAndSetDisabled])

  const handleGetEditData = useCallback(async() => {
    if(id) {
        try {
            const res = await request({url:`/listing/getListing?id=${id}`, method: 'get'})
            setForm({
                name: res.data.name,
                category: res.data.category.id.toString(),
                type: res.data.type.id.toString(),
                city: res.data.city,
                city_id: res.data.city_id.toString(),
                district: res.data.district,
                district_id: res.data.district_id.toString(),
                neighborhoods: res.data.neighborhoods,
                neighborhoods_id: res.data.neighborhoods_id.toString(),
                map_link: res.data.map_link,
                location: res.data.location,
                short_desc: res.data.short_desc
            })
            setPrice({
                float: res.data.price,
                formatted: new Intl.NumberFormat('tr-TR', { 
                    style: 'currency', 
                    currency: 'TRY' 
                }).format(res.data.price),
                value: `${res.data.price}`
            })
            setImagesPre(res.data.images)
            setImages(res.data.images.map(() => ('')))
            setDesc(res.data.desc)
            setProps(res.data.features)
            setInfos(res.data.infos)
            setCreatedAt(res.data.createdAt)
        } catch (error: any) {
            throw new Error(error)
        }
    }
  }, [id])

  useEffect(() => {
    handleGetEditData();
  }, [handleGetEditData])
  

  return (
    <MainWrapper>
        <Stage
            stage={stage}
        />
        <div className="w-full h-full px-2 overflow-y-auto">
        { stage === 1 && (
            <BaseInfo
            setImages={setImages}
            setImagesPre={setImagesPre}
            images={images}
            imagesPre={imagesPre}
            form={form}
            setForm={setForm}
            setPrice={setPrice}
            price={price}
            categories={categories}
            setCategoryName={setCategoryName}
            categoryName={categoryName}
            types={types}
            id={id}
            />
        )}
        { stage === 2 && (
            <DescSeo
            form={form}
            setForm={setForm}
            desc={desc}
            setDesc={setDesc}
            />
        )}
        { stage === 3 && (
            <ProperiesWrapper
                props={props}
                setProps={setProps}
                infos={infos}
                setInfos={setInfos}
                id={id}
            />
        )}
        { stage === 4 && (
            <Preview
                imagesPre={imagesPre}
                price={price}
                form={form}
                desc={desc}
                props={props}
                infos={infos}
                types={types}
                createdAt={createdAt}
            />
        )}
        </div>
        <div className="flex justify-end gap-3 px-5">
            <Button disabled={stage === 1} onClick={handleStageBack}>
                Geri
            </Button>
            <Button 
            disabled={isDisabled}
            onClick={handleStage} variant={stage < 4 ? 'first' : 'accept'}>
                {stage < 4 ? 'Devam Et' : 'Onayla'}
            </Button>
        </div>

        {
        loading && (
          <ListLoading/>
        )
      }
    </MainWrapper>
  )
}

export default AddListPage