/* eslint-disable @typescript-eslint/no-explicit-any */
import { AlertDialogComp } from "@/components/customUI/Alert"
import Image from "@/components/customUI/image"
import { Button } from "@/components/ui/button"
import { API_URL } from "@/config"
import { IListingTypeValue } from "@/types"
import TypeValuePopup from "./TypeValuePopup"
import request from "@/utils/request"
import { useToast } from "@/hooks/use-toast"

const TypeValue = ({
    data,
    getData,
    group_id
  }: {
    data: IListingTypeValue
    getData: () => void
    group_id: number | undefined
  }) => {

    const {toast} = useToast()

    const handleDelete = async () => {
        try {
            const res = await request({url:  `/info/value/delete?id=${data.id}`, data: {}})
            if(res.data.error) {
                toast({title:'İşlem başarısız!', description: res.data.message})
            }  else {
                toast({title:'İşlem başarılı.', description: res.data.message})
                getData()
            }
        } catch (error: any) {
            throw new Error(error)
        }
    }

  return (
    <div className="pb-2 border-b border-border w-full flex items-center justify-between">
        <div className="flex items-center gap-2">
            <div className="w-5 h-5 overflow-hidden">
                {
                    data.icon && (
                        <Image
                            src={`${API_URL}/${data.icon}`}
                        />
                    )
                }
            </div>
            <p className="text-sm font-semibold">{data.name}</p>
        </div>
        <div className="flex items-center gap-3">
            {data.preview && 
            (<span className="text-xs font-semibold bg-first px-3 py-1 rounded-full">Ön İzleme</span>)
            }
            <TypeValuePopup
                data={data}
                getData={getData}
                typeId={group_id!}
            />
            <AlertDialogComp
                customButton={
                    <Button variant={'reject'}>
                        Sil
                    </Button>
                }
                title="İlan tipini değerini silmek istediğinize emin misiniz?"
                handleAccept={handleDelete}
            />
        </div>
    </div>
  )
}

export default TypeValue