/* eslint-disable @typescript-eslint/no-explicit-any */
import { ImageIcon, Trash } from "lucide-react";
import { ChangeEvent } from "react";
import { useFileHandler } from "@/hooks/useFileHandler";
import Image from "@/components/customUI/image";
import { cn } from "@/lib/utils";
import Heading from "@/components/customUI/Heading";
import { API_URL } from "@/config";
import { AlertDialogComp } from "@/components/customUI/Alert";
import { useToast } from "@/hooks/use-toast";
import request from "@/utils/request";

const MAX_IMAGES = 10;

const ImageWrapper = ({
    setImagesPre,
    setImages,
    images,
    imagesPre,
    id
}: {
    setImages: React.Dispatch<React.SetStateAction<any[]>>
    setImagesPre: React.Dispatch<React.SetStateAction<string[]>>
    images: any[]
    imagesPre: string[]
    id: string | null
}) => {

    const { handleSelectFiles } = useFileHandler();
    const {toast} = useToast();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSelectFiles(e, setImages, setImagesPre, images);
    };

    const handleDeleteImage = async(index: number,) => {
        const updatedImages = images.filter((_, i) => i !== index);
        const updatedImagesPre = imagesPre.filter((_, i) => i !== index);
        setImages(updatedImages);
        setImagesPre(updatedImagesPre);
    };

    const getImageSrc = (image: string) => {
        return image.includes("uploads/list/") ? `${API_URL}/${image}` : image;
    };

    const handleDelete = async (index: number,src:string) => {
        try {
            const res = await request({url: `/listing/deleteImage?id=${id}`, data: {image:src}})
            const updatedImages = images.filter((_, i) => i !== index);
            const updatedImagesPre = imagesPre.filter((_, i) => i !== index);
            setImages(updatedImages);
            setImagesPre(updatedImagesPre);
            toast({title: 'İşlem Başarılı.', description: res.data.message});
        } catch (error) {
            const err = error as { status: number, response: { data: { message: string } } };
            toast({
                title: "İşlem Başarısız!",
                description: err.response.data.message
                })
        }
    }

    return (
        <div className="col-span-5 space-y-3">
            <Heading title="Resimler"/>
            <div className="w-full grid grid-cols-3 lg:grid-cols-5 xl:grid-cols-10 gap-3">
                {Array.from({ length: MAX_IMAGES }).map((_, i) => (
                    <div key={i} className="flex flex-col w-full gap-3 hover:bg-lightBg hover:dark:bg-[#f5f5f5] duration-300">
                        {imagesPre[i] ? (
                            <div className="w-full flex-col">
                                <div className="relative w-full aspect-square overflow-hidden border border-border rounded-xl bg-white">
                                    <Image
                                        alt="image"
                                        existSrcSet={false}
                                        src={getImageSrc(imagesPre[i]) as string}
                                    />
                                    <input
                                        accept="image/*"
                                        type="file"
                                        multiple
                                        className="absolute inset-0 opacity-0"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div className="flex gap-1 mt-2">
                                    {
                                    imagesPre[i].includes('uploads/list/') ?
                                    (<AlertDialogComp
                                        title="Bu resimi silmek istediğinize emin misiniz?"
                                        customButton={
                                            <button    
                                                className={cn('w-full',
                                                    "py-2 bg-gradient-to-r from-red-500 to-red-700 flex items-center text-white justify-center rounded-xl hover:opacity-80 duration-300"
                                                )}
                                            >
                                                <Trash size={14} />
                                            </button>
                                        }
                                        handleAccept={() => handleDelete(i, imagesPre[i])}
                                    />)
                                    :
                                    (<button
                                        onClick={() => handleDeleteImage(i)}
                                        className={cn('w-full',
                                            "py-2 bg-gradient-to-r from-red-500 to-red-700 flex items-center text-white justify-center rounded-xl hover:opacity-80 duration-300"
                                        )}
                                    >
                                        <Trash size={14} />
                                    </button>)
                                    }
                                </div>
                            </div>
                        ) : (
                            i >= imagesPre.length && (
                                <div className="w-full overflow-hidden relative aspect-square border border-thirth rounded-xl flex items-center justify-center">
                                    <div className="flex flex-col items-center justify-center">
                                        <ImageIcon />
                                    </div>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        multiple
                                        className="absolute inset-0 opacity-0"
                                        onChange={handleFileChange}
                                    />
                                </div>
                            )
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageWrapper;
