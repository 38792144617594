/* eslint-disable @typescript-eslint/no-explicit-any */
import MainWrapper from "@/components/customUI/MainWrapper"
import Menu from "./Menu"
import { PaginationComp } from "@/components/customUI/PaginationComp"
import Advert from "./Advert"
import { useCallback, useEffect, useState } from "react"
import { IListing } from "@/types"
import request from "@/utils/request"
import { useSearchParams } from "react-router-dom"
import { SearchX } from "lucide-react"
import AdvertLoading from "./AdvertLoading"

const Listing = () => {

  const [data, setData] = useState<IListing[]>([])
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams({limit: '10', page: '1'});
  const [page, setPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page') || '1') : 1)

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);

    params.page = page.toString();

    setSearchParams(params);
  }, [page, searchParams, setSearchParams]);

  const handleGetData = useCallback(async () => {
    try {
      setLoading(true);
  
      // URL'den filtre parametrelerini alıyoruz
      const name = searchParams.get('name') || '';
      const categoryId = searchParams.get('categoryId');
      const districtId = searchParams.get('districtId');
      const neighborhoodsId = searchParams.get('neighborhoodsId');
      const highlights = searchParams.get('highlights');
      const isActive = searchParams.get('isActive');
      const page = searchParams.get('page') || '1';
      const limit = searchParams.get('limit') || '10';
  
      // Filtre parametrelerini DTO'ya uygun hale getiriyoruz
      const params: {
        name?: string;
        districtId?: number;
        neighborhoodsId?: number;
        highlights?: boolean;
        isActive?: boolean;
        categoryId?: number;
        page?: number;
        limit?: number;
      } = {};
  
      if (name) params.name = name;
      if (categoryId) params.categoryId = parseInt(categoryId, 10);
      if (districtId) params.districtId = parseInt(districtId, 10);
      if (neighborhoodsId) params.neighborhoodsId = parseInt(neighborhoodsId, 10);
      if (highlights) params.highlights = highlights === '1' ? true : false;
      if (isActive) params.isActive = isActive === '1' ? true : false;
      if (page) params.page = parseInt(page, 10);
      if (limit) params.limit = parseInt(limit, 10);
  
      // API isteği yapıyoruz
      const res = await request({ url: '/listing/filtered-list', data: params });
  
      // API sonucunu state'e kaydediyoruz
      setData(res.data.data);
      setTotal(res.data.total);
      setTotalPages(res.data.totalPages);
    } catch (error: any) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  }, [searchParams]);
  

  useEffect(() => {
    handleGetData()
  }, [handleGetData])
  

  return (
    <MainWrapper>
      <Menu
        total={total}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
        <div className="w-full h-full overflow-y-auto px-2 lg:px-5 space-y-3">
          {!loading ?
            data.length > 0 ?
            data.map((v, i) => (
              <Advert
                key={i}
                data={v}
                getData={handleGetData}
              />
            ))
            :
            <div className="flex items-center justify-center flex-col gap-3 w-full h-full text-lg font-semibold text-[#A9B0B9]">
                <SearchX size={30}/>
                <p>Sonuç bulunamadı...</p>
            </div>
            :
            [...Array(4)].map((_, i) => (
              <AdvertLoading key={i}/>
            ))
          }
        </div>
        {totalPages > 0 && 
        (<PaginationComp
          totalPages={totalPages}
          setPage={setPage}
          currentPage={page}
        />)}
    </MainWrapper>
  )
}

export default Listing