import { cn } from "@/lib/utils"

const Stage = ({
    stage
}: {
    stage: number
}) => {

    const stages = [
        {name: 'Temel İlan Bilgileri', number: 1, isActive: stage === 1},
        {name: 'Açıklama', number: 2, isActive: stage === 2},
        {name: 'İlan Özellikleri', number: 3, isActive: stage === 3},
        {name: 'Ön İzleme ve Onay', number: 4, isActive: stage === 4},
    ]

  return (
    <>
    <div className="w-full max-lg:hidden py-5 shrink-0 px-20 bg-[#e5e5e5] rounded-xl flex items-center justify-between">
        {stages.map((v, i) => (
            <div key={i} className="flex items-center gap-3">
            <span 
            className={cn("rounded-full w-8 aspect-square flex items-center justify-center text-sm font-semibold",
                v.isActive ? 'bg-first' : 'bg-white'
            )}>
                {v.number}
            </span>

            <p className={cn("text-sm font-semibold ",
            )}>{v.name}</p>
        </div>
        ))}
    </div>
    <div className="w-full py-3 bg-first text-center text-xs font-semibold lg:hidden">
        {stages.find((v) => v.number === stage)?.name}
    </div>
    </>
  )
}

export default Stage