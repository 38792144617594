/* eslint-disable @typescript-eslint/no-explicit-any */
import SelectComp from "@/components/customUI/SelectComp"
import { Input } from "@/components/ui/input"
import { CategoriesProps } from "@/types"
import request from "@/utils/request"
import { Filter, FilterX } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { District, Neighborhoods } from "../AddList/BaseInfo/Form"
import SelectCompForAdres from "@/components/customUI/SelectCompForAdres"
import { SetURLSearchParams } from "react-router-dom"
import SelectCategory from "../AddList/BaseInfo/SelectCategory"

const Menu = ({
    total,
    setSearchParams,
    searchParams,
}: {
    total:number
    setSearchParams: SetURLSearchParams
    searchParams: URLSearchParams
}) => {

    const [categories, setCategories] = useState<CategoriesProps[]>([])
    const [categoryName, setCategoryName] = useState('')
    const [selectedCategory, setSelectedcategory] = useState('')
    const [name, setName] = useState('')
    const [districts, setDistricts] = useState<District[]>([])
    const [district, setDistrict] = useState('')
    const [neighborhoods, setNeighborhoods] = useState<Neighborhoods[]>([])
    const [neighborhood, setNeighborhood] = useState('')
    const [isActive, setIsActive] = useState('')
    const [highlights, setHighlights] = useState('')

    const handleCategories = useCallback(async () => {
        try {
        const res = await request({url: `category/select-all?name=${categoryName}`, method: 'get'})
        setCategories(res.data)
        } catch (err: any) {
        throw new Error(err)
        }
    }, [categoryName])

    useEffect(() => {
        handleCategories()
    }, [handleCategories])

      const getDistricts = useCallback(async () => {
            try {
                const response = await request({url: `/address/districts?city_id=54`, method: 'get'});
                setDistricts(response.data);
                } catch (err) {
                console.error(err);
                }
        }, []);

        const getNeighborhoods = useCallback(async () => {
        if(district) {
            try {
                const response = await request({url: `/address/neighborhoods?district_id=${district}`, method: 'get'});
                setNeighborhoods(response.data);
                } catch (err) {
                console.error(err);
                }
        }
        }, [district]);

        /* useEffect(() => {
        getCities()
        }, [getCities]) */

        useEffect(() => {
        getDistricts()
        }, [getDistricts])

        useEffect(() => {
        getNeighborhoods()
        }, [getNeighborhoods])

        const addQueryParams = () => {
            const currentParams = Object.fromEntries([...searchParams]);
          
            const newParams = {
              name: name, // İsim
              categoryId: selectedCategory,
              districtId: district,
              neighborhoodsId: neighborhood,
              isActive: isActive ?? undefined,
              highlights: highlights ?? undefined,
            };
          
            // Geçerli parametrelerle güncelle
            for (const [key, value] of Object.entries(newParams)) {
              if (value) {
                currentParams[key] = value;
              }
            }
          
            setSearchParams(currentParams); // Yeni parametrelerle arama parametrelerini güncelle
          };

          const handleClearFilter = () => {
            // Tüm filtreleri sıfırlar ve varsayılan değerlerle günceller
            setSearchParams({ limit: '10', page: '1' }); // Varsayılan sayfa ve limit
            setName(''); // İsim filtresini sıfırla
            setSelectedcategory(''); // Seçilen kategoriyi sıfırla
            setDistrict(''); // Seçilen ilçeyi sıfırla
            setNeighborhood(''); // Seçilen mahalleyi sıfırla
            setIsActive(''); // Aktif/Pasif filtresini sıfırla
            setHighlights(''); // Öne çıkan filtresini sıfırla
          };
          

  return (
    <div className="w-full h-[120px] rounded-xl shadow-lg border border-border overflow-hidden flex">
        <div className="w-full py-2.5 pl-5 flex items-center gap-20">
            <p className="text-sm shrink-0">
                İlan adeti: <b>{total}</b>
            </p>

            <div className="grid grid-cols-3 gap-3 w-full px-5">
            <SelectCategory
                    setCategoryName={setCategoryName}
                    categoryName={categoryName}
                    categories={categories}
                    onValueChange={(e: string) => setSelectedcategory(e)}
                    value={selectedCategory}
                />
            <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Ürün Adı"/>

                <SelectCompForAdres
                    data={districts}
                    onValueChange={(e) => setDistrict(e)}
                    value={district}
                    label="İlçeler"
                    placeholder="İlçe Seçin"
                />

                <SelectCompForAdres
                        data={neighborhoods}
                        onValueChange={(e) => setNeighborhood(e)}
                        value={neighborhood}
                        label="Mahalleler/Semtler"
                        placeholder="Mahalle/Semt Seçin"
                        disabled={!district}
                />

                <SelectComp
                placeholder="İlan Durumu"
                label="İlan Durumu"
                onValueChange={(e) => setIsActive(e)}
                value={isActive}
                data={[{name: 'Açık', value: '1'}, {name: 'Kapalı', value: '0'}]}
                />
                <SelectComp
                placeholder="Öne Çıkanlar"
                label="Öne Çıkanlar"
                onValueChange={(e) => setHighlights(e)}
                value={highlights}
                data={[{name: 'Açık', value: '1'}, {name: 'Kapalı', value: '0'}]}
                />
            </div>
        </div>

        <div className="h-full w-[60px] bg-[#e5e5e5] shrink-0 flex flex-col gap-2 items-center justify-center">
            <button
            onClick={addQueryParams}
            className="filter-button">
                <Filter className="text-white"/>
            </button>
            <button
            onClick={handleClearFilter}
            className="filter-clear-button">
                <FilterX className="text-white"/>
            </button>
        </div>
    </div>
  )
}

export default Menu