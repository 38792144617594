/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ChangeEvent, useState } from 'react';
import { useToast } from './use-toast';

interface ImageProps {
  data: File | null;
  pre: string | ArrayBuffer | null;
}

export const useFileHandler = () => {
  const { toast } = useToast();

  const [image, setImage] = useState<ImageProps>({ data: null, pre: null });
  const [images, setImages] = useState<ImageProps[]>([]);

  const handleSelectFile = (
    e: ChangeEvent<HTMLInputElement>,
    setFile: (file: File) => void,
    setFilePre?: (filePreview: string | ArrayBuffer | null) => void,
    fileSizeMb: number = 2
  ): void => {
    const selectedFile = e.target.files?.[0];
    const maxFileSize = fileSizeMb * 1024 * 1024;
  
    if (selectedFile && selectedFile.size <= maxFileSize) {
      setFile(selectedFile);
  
      const reader = new FileReader();
      reader.onload = () => {
        if (setFilePre) {
          setFilePre(reader.result);
        }
      };
  
      reader.readAsDataURL(selectedFile);
    } else if (selectedFile) {
      toast({
        title: 'Geçersiz Dosya Boyutu',
        description: `Dosya ${fileSizeMb}Mb'tan büyük olamaz.`
      });
    }
  };

  const handleSelectFiles = (
    e: ChangeEvent<HTMLInputElement>,
    setFiles: React.Dispatch<React.SetStateAction<any[]>>,
    setFilePreviews: React.Dispatch<React.SetStateAction<string[]>>,
    images: string[],
    fileSizeMb: number = 2,
  ): void => {
    const selectedFiles = Array.from(e.target.files || []);
    const maxFileSize = fileSizeMb * 1024 * 1024;

    //@ts-ignore
    const currentImagesLength = images?.length || 0;

    if (currentImagesLength + selectedFiles.length > 10) {
      toast({
        title: 'Maksimum Dosya Sayısı Aşıldı',
        description: `Toplamda en fazla 10 dosya seçebilirsiniz. Şu anda ${currentImagesLength} resim seçili.`
      });
      return;
    }

    const validFiles: File[] = [];
    const filePreviews: (string | ArrayBuffer | null)[] = [];

    selectedFiles.forEach((file) => {
      if (file.size <= maxFileSize) {
        validFiles.push(file);

        const reader = new FileReader();
        reader.onload = () => {
          filePreviews.push(reader.result);
          if (filePreviews.length === validFiles.length && setFilePreviews) {
            //@ts-ignore
            setFilePreviews(prev => prev ? [...prev, ...filePreviews] : filePreviews);
          }
        };
        reader.readAsDataURL(file);
      } else {
        toast({
          title: 'Geçersiz Dosya Boyutu',
          description: `${file.name} dosyası ${fileSizeMb}Mb'tan büyük olamaz.`
        });
      }
    });

    if (validFiles.length > 0) {
      //@ts-ignore
      setFiles(prev => [...prev, ...validFiles]);
    }
  };


  return { handleSelectFile, handleSelectFiles, image, setImage, images, setImages };
};
