import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedHome from "./components/layout/ProtectedHome";
import LoginPage from "./components/pages/Login";
import PanelLayout from "./components/layout/PanelLayout";
import ProtectedRoute from "./components/layout/ProtectedRoute";
import Listing from "./components/pages/List";
import Categories from "./components/pages/Categories";
import ListTypePage from "./components/pages/ListType";
import Properties from "./components/pages/Properties";
import AddListPage from "./components/pages/AddList";
import { Toaster } from "@/components/ui/toaster"
import BlogPage from "./components/pages/BlogPage";
import AddBlog from "./components/pages/BlogPage/AddBlog";
import Settings from "./components/pages/Settings";

function App() {

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedHome/>}>
          <Route element={<LoginPage/>} path="/"/>
        </Route>

        <Route element={<PanelLayout/>}>
          <Route element={<ProtectedRoute/>}>
            <Route element={<Listing/>} path="/ilanlar"/>
            <Route element={<Categories/>} path="/kategoriler"/>
            <Route element={<ListTypePage/>} path="/ilan-tipi"/>
            <Route element={<Properties/>} path="/ozellikler"/>
            <Route element={<AddListPage/>} path="/ilan-ekle"/>
            <Route element={<BlogPage/>} path="/blog"/>
            <Route element={<Settings/>} path="/ayarlar"/>
            <Route element={<AddBlog/>} path="/blog/ekle"/>
            <Route element={<AddBlog/>} path="/blog/duzenle/:id"/>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
    <Toaster />
    </>
  )
}

export default App
