import MainWrapper from "@/components/customUI/MainWrapper"
import TypeValue from "./TypeValue"
import { IListingTypeValue } from "@/types"
import { Star } from "lucide-react"
import VariantLoading from "./VariantLoading"
import TypeValuePopup from "./TypeValuePopup"

const TypeValues = ({
    values,
    getData,
    loading,
    group_id
}: {
    values: IListingTypeValue[] | []
    getData: () => void,
    loading: boolean
    group_id: number | undefined
}) => {
  return (
    <MainWrapper className="rounded-md border border-border overflow-hidden">
        <div className="w-full bg-[#e5e5e5] px-5 py-3 shrink-0 flex items-center justify-between">
            <p className="font-semibold">İlan Tipi Değerleri</p>
            <TypeValuePopup
                typeId={group_id!}
                getData={getData}
            />
        </div>
        <div className="h-full overflow-y-auto flex flex-col gap-3 p-5">
            {
                !loading ?
                values.length > 0 ?
                values.map((v, i) => (
                    <TypeValue
                        key={i}
                        data={v}
                        getData={getData}
                        group_id={group_id}
                    />
                ))
                : (
                    <div className="flex items-center justify-center flex-col w-full h-full gap-3">
                        <Star size={40}/>
                        <p className="text-sm font-semibold">İlan Tipi değeri bulunamadı...</p>
                    </div>
                )
                :
                [...Array(10)].map((v) => (
                    <VariantLoading
                        key={v}
                    />
                ))
            }
        </div>
    </MainWrapper>
  )
}

export default TypeValues