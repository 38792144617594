import { useToast } from '@/hooks/use-toast'
import { IFeatureForListing, IFeatureValue } from '@/types'
import { Dot, Trash } from 'lucide-react'

const AddedProps = ({
    data,
    setProps,
    deleteFunc
}: {
    data: IFeatureForListing,
    setProps: React.Dispatch<React.SetStateAction<IFeatureForListing[]>>
    deleteFunc: () => void
}) => {

    const {toast} = useToast()

  const handleDelete = (v: IFeatureValue) => {
    if (data.featureValues.length === 1) {
        toast({ title: 'İşlem Başarısız', description: "En az bir tane seçenek değeri seçin!" });
        return;
    }

    const updatedValues = data.featureValues.filter(_v => _v.id.toString() !== v.id.toString());
    setProps(prevVariants => prevVariants.map(vr => 
        vr.feature.id.toString() === data.feature.id.toString()
            ? { ...vr, featureValues: updatedValues } 
            : vr
    ));
};

  return (
    <div className='space-y-3 text-sm py-2 border-b border-border'>
        <div className='flex items-center justify-between gap-3 w-full'>
            <p className='font-semibold'>{data.feature.name}</p>
            <button 
            className='text-white bg-red-500 p-2 rounded-md hover:bg-opacity-80 duration-300'
            onClick={deleteFunc}>
                <Trash size={16}/>
            </button>
        </div>
        <div className='flex items-center gap-3 px-3 flex-wrap'>
            {
                data.featureValues.map((d, i) => (
                    <div key={i} className='flex items-center gap-2'>
                        <Dot size={24}/>
                        <p>{d.value}</p>
                        <button 
                        onClick={() => handleDelete(d)}
                        className='bg-red-500 text-white p-1 hover:bg-opacity-80 duration-300 rounded-full'>
                            <Trash size={16}/>
                        </button>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default AddedProps