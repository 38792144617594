/* eslint-disable @typescript-eslint/no-explicit-any */
import SelectComp from "@/components/customUI/SelectComp"
import { useToast } from "@/hooks/use-toast"
import { IFeature, IFeatureForListing, IFeatureValue } from "@/types"
import request from "@/utils/request"
import { PlusCircle } from "lucide-react"
import { FC, useCallback, useEffect, useState } from "react"
import OptionValues from "./OptionValues"


interface OptionProps {
    setProps: React.Dispatch<React.SetStateAction<IFeatureForListing[]>>
    props: IFeatureForListing[]
}

const AddPropsWrapper : FC<OptionProps> = ({
    props,
    setProps
}) => {

    const [groups, setGroups] = useState<IFeature[]>([])
    const [groupId, setGroupId] = useState('')
    const {toast} = useToast()
    const [options, setOptions] = useState<IFeatureValue[]>([])
    const [selectedOption, setSelectedOption] = useState<IFeatureValue[]>([])

    const getGroups = useCallback(async() => {
        try {
            const res = await request({url: '/feature/all', method: 'get'})
            setGroups(res.data)
        } catch (error:any) {
            throw new Error(error)
        }
    }, [])

    useEffect(() => {
        getGroups()
    }, [getGroups])

    const getOptions = useCallback(async() => {
        try {
            if(groupId) {
                const res = await request({url: `/feature/allValue?id=${groupId}`, method: 'get'})
                setOptions(res.data)
            }
        } catch (error:any) {
            throw new Error(error)
        }
    }, [groupId])

    useEffect(() => {
        getOptions()
    }, [getOptions])

    const template = {
        feature: groups.find((v) => (v.id).toString() === groupId.toString())!,
        featureValues: selectedOption
    }

    const addVariants = () => {
        if(groupId) {
            if(!props.find((v) => v.id === template.feature?.id)) {
                if(selectedOption.length > 0) {
                    setProps([...props, template])
                    setGroupId('')
                    setSelectedOption([])
                } else {
                    toast({title: 'İşlem Başarısız', description: "En az bir tane seçenek değeri seçin!"})
                }
            } else {
                toast({title: 'İşlem Başarısız', description: "Bu seçenek grubu zaten mevcut!"})
            }
        } else {
            toast({title: 'İşlem Başarısız', description: "Öncelikle seçenek grubunu seçiniz!"})
        }
    }

    const handleDeleteOptions = (id: string) => {
        setSelectedOption(selectedOption.filter((o) => o.id.toString() !== id.toString()))
    }

    useEffect(() => {
        setSelectedOption([])
    }, [groupId])
    

  return (
    <div className="w-full flex items-start gap-3 max-lg:flex-col">
        <div className="w-full lg:w-1/4 shrink-0">
            <SelectComp
                data={groups.map((v) => ({name: v.name, value: v.id.toString()}))}
                placeholder="Özellik Grubu Seç"
                label="Özellik Grupları"
                value={groupId}
                onValueChange={(e) => setGroupId(e)}
            />
        </div>

        <div className="flex items-center gap-3 w-full">
        <div className="w-full flex relative pr-[110px] flex-wrap gap-2 min-h-10 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
        {
                selectedOption?.map((_, i) => (
                    <div key={i} className="h-full shrink-0 flex items-center gap-3 pl-3 overflow-hidden bg-[#e5e5e5] rounded-md">
                        <p className="font-medium text-xs">{_.value}</p>

                        <button 
                        onClick={() => handleDeleteOptions(_.id.toString())}
                        className="text-[10px] font-semibold px-2 h-full bg-red-500 text-white hover:bg-opacity-80 duration-300">Sil</button>
                    </div>
                ))
            }

            <OptionValues
                options={options}
                disabled={!groupId}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />
        </div>

        <button
            onClick={addVariants}
            className="h-10 aspect-square shrink-0 bg-first hover:bg-opacity-70 duration-300 rounded-md flex items-center justify-center">
            <PlusCircle/>
        </button>
        </div>
    </div>
  )
}

export default AddPropsWrapper