import Home from '@/assets/icon/home.svg'
import { Calendar, Star, StarOff } from "lucide-react"
import { onlyDate, priceMasking } from "@/utils/helpers"
import Image from '@/components/customUI/image'
import Icon from '@/components/customUI/icon'
import { Button } from '@/components/ui/button'
import Edit from '@/assets/icon/edit.svg'
import Trash from '@/assets/icon/trash.svg'
import { IListing } from '@/types'
import { API_URL } from '@/config'
import { subDays, isAfter } from 'date-fns';
import request from '@/utils/request'
import { useToast } from '@/hooks/use-toast'
import { Switch } from '@/components/ui/switch'
import { AlertDialogComp } from '@/components/customUI/Alert'

const Advert = ({
    data,
    getData
}: {
    data: IListing,
    getData: () => void
}) => {
    const oneWeekAgo = subDays(new Date(), 7);
    const isNew = isAfter(new Date(data.createdAt), oneWeekAgo);

    const {toast} = useToast()

    const handleToggleHighlight = async() => {
        try {
            const res = await request({url: `/listing/highlight?id=${data.id}`, method: 'get'});
            toast({title: 'İşlem başarılı', description: res.data.message})
            getData()
        } catch (error) {
            const err = error as { status: number, response: { data: { message: string } } };
                toast({
                title: "İşlem Başarısız!",
                description: err.response.data.message
                })
        }
    }

    const handleToggleActive = async() => {
        try {
            const res = await request({url: `/listing/isActive?id=${data.id}`, method: 'get'});
            toast({title: 'İşlem başarılı', description: res.data.message})
            getData()
        } catch (error) {
            const err = error as { status: number, response: { data: { message: string } } };
                toast({
                title: "İşlem Başarısız!",
                description: err.response.data.message
                })
        }
    }

    const handleDelete = async() => {
        try {
            const res = await request({url: `/listing/delete?id=${data.id}`, method: 'get'});
            toast({title: 'İşlem başarılı', description: res.data.message})
            getData()
        } catch (error) {
            const err = error as { status: number, response: { data: { message: string } } };
                toast({
                title: "İşlem Başarısız!",
                description: err.response.data.message
                })
        }
    }

    const handleEdit = () => {
        window.location.href = `/ilan-ekle?id=${data.id}`
    };

  return (
    <div className="w-full shrink-0 border border-border rounded-xl overflow-hidden flex">
        <div className="w-[240px] flex shrink-0 aspect-[240/160] relative">
            <Image src={`${API_URL}/${data!.images![0]}`}/>
            <div className="flex items-center gap-1 absolute top-0 left-0">
                {data.highlights && 
                (<span className="px-2 py-1 rounded-xl text-xs font-semibold bg-first text-white uppercase ">
                    Öne Çıkan
                </span>)}
                {isNew && (
                <span className="px-2 py-1 rounded-xl text-xs font-semibold bg-red-500 text-white uppercase">
                    YENİ
                </span>
                )}
            </div>
        </div>
        <div className='flex justify-between w-full p-5 gap-5'>
            <div className=" space-y-3 w-full">
                <div className="flex items-center w-full justify-between gap-5">
                    <p className="w-full truncate text-sm font-semibold">{data.name}</p>
                </div>
                <p className="text-xs font-semibold text-third-dark">{data.neighborhoods} / {data.district}</p>
                <div className="flex font-semibold items-center gap-2 lg:gap-3 text-xs text-third-dark flex-wrap">
                    <div className="flex items-center gap-1">
                        <Icon
                            icon={<Home/>}
                            color="#A9B0B9"
                            width="16px"
                        />
                        <p>{data.type?.name}</p>
                    </div>
                    {data.infos.map((v, i) => (
                        v.typeValue.preview && (
                            <div key={i} className="flex items-center gap-2">
                                <div className="w-4 h-4 shrink-0">
                                    <Image
                                        src={`${API_URL}/${v.typeValue.icon}`}
                                        alt={v.typeValue.name} // Alternatif metin eklemek iyi bir uygulamadır.
                                    />
                                </div>
                                <p>
                                    {v.typeValue.name.toLowerCase().includes("metrekare") 
                                        ? `${v.value} m²` 
                                        : v.value}
                                </p>
                            </div>
                        )
                    ))}
                    <p className="flex items-center gap-1"><Calendar size={16}/> {onlyDate(new Date(data.createdAt))}</p>
                </div>
                <div className='flex items-center gap-3'>
                    <p className="text-sm lg:text-xl font-semibold">{priceMasking(data.price)}</p>
                    <p className='text-xs font-semibold text-[#A9B0B9]'>{data.is_active ? 'Aktif' : 'Pasif'}</p>
                    <Switch onClick={handleToggleActive} checked={data.is_active}/>
                </div>
            </div>
            <div className='flex flex-col gap-3 shrink-0 min-w-[161px]'>
                <Button 
                onClick={handleToggleHighlight}
                variant={'first'} className='gap-3'>
                    {data.highlights ? <StarOff/> : <Star/> }
                    {data.highlights ? 'Öne Çıkarma' : 'Öne Çıkar'}
                </Button>
                <Button onClick={handleEdit} variant={'accept'} className='gap-3'>
                    <Icon color='#fff' icon={<Edit/>}/> 
                    Düzenle
                </Button>
                <AlertDialogComp
                    customButton={
                        <Button 
                        variant={'reject'} className='gap-3'>
                            <Icon color='#fff' icon={<Trash/>}/> 
                            Sil
                        </Button>
                    }
                    handleAccept={handleDelete}
                    title='Bu ilanı silmek istediğinize emin misiniz?'
                />
            </div>
        </div>
    </div>
  )
}

export default Advert