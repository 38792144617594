import { useAuth } from '@/contexts/AuthProvider';
import { Button } from '../ui/button'
import Logo from '@/assets/icon/logo.svg'
import Icon from '../customUI/icon';

const Header = () => {

    const { logout } = useAuth();

  return (
    <header className="lg:mb-5 h-[70px] flex items-center justify-between bg-white border border-border sticky top-0 lg:shadow-lg rounded-xl px-3">
        <Icon
            icon={<Logo/>}
            width="120px"
            height="auto"
        />

        <div className='flex items-center gap-5'>

            <Button variant={'destructive'} onClick={logout}>
                Çıkış Yap
            </Button>
        </div>
      </header>
  )
}

export default Header