import { Skeleton } from "@/components/ui/skeleton"

const AdvertLoading = () => {
  return (
    <div className="w-full shrink-0 border border-border rounded-xl overflow-hidden flex">
        <div className="w-[240px] flex shrink-0 aspect-[240/160] relative">
            <Skeleton className="w-full h-full"/>
        </div>
        <div className='flex justify-between w-full p-5 gap-5'>
            <div className=" space-y-3 w-full">
                <Skeleton className="w-[300px] h-5 rounded-full"/>
                <Skeleton className="w-[180px] h-4 rounded-full"/>
                <Skeleton className="w-[280px] h-4 rounded-full"/>
                <Skeleton className="w-[220px] h-7 rounded-full"/>
            </div>
            <div className='flex flex-col gap-3 shrink-0 min-w-[161px]'>
                <Skeleton className="w-full h-10"/>
                <Skeleton className="w-full h-10"/>
                <Skeleton className="w-full h-10"/>
            </div>
        </div>
    </div>
  )
}

export default AdvertLoading