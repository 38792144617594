import MainWrapper from "@/components/customUI/MainWrapper"
import AddPropsWrapper from "./AddPropsWrapper"
import { IFeatureForListing } from "@/types"
import AddedProps from "./AddedProps"

const Props = ({
  setProps,
  props
}: {
    setProps: React.Dispatch<React.SetStateAction<IFeatureForListing[]>>
    props: IFeatureForListing[]
}) => {

  const handleDelete = (id: string) => {
    setProps(props.filter((v) => v.feature.id.toString() !== id));
}

  return (
    <MainWrapper className="rounded-md border border-border overflow-hidden">
        <div className="w-full py-3 px-5 bg-[#e5e5e5] shrink-0 flex items-center justify-between">
            <p className="font-semibold">İlan Özellikleri</p>
        </div>
        <MainWrapper className="lg:p-5 p-3 space-y-5">
            <AddPropsWrapper
              props={props}
              setProps={setProps}
            />
            <div className="w-full h-full overflow-y-auto px-3 p-3 lg:pb-5">
              {
                props?.map((v, i) => (
                  <AddedProps
                    key={i}
                    data={v}
                    setProps={setProps}
                    deleteFunc={() => handleDelete(v.feature.id.toString())}
                  />
                ))
              }
            </div>
        </MainWrapper>
    </MainWrapper>
  )
}

export default Props