import Image from '@/components/customUI/image'
import MainWrapper from '@/components/customUI/MainWrapper'
import { Input } from '@/components/ui/input'
import { API_URL } from '@/config'
import { IListingInfo } from '@/types'
import { ChangeEvent } from 'react'

const InfosWrapper = ({
    infos,
    setInfos,
}: {
    infos: IListingInfo[],
    setInfos: React.Dispatch<React.SetStateAction<IListingInfo[]>>
}) => {

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;

        const updatedInfos = infos.map((item, i) => {
            if (i === index) {
                return { ...item, value };
            }
            return item;
        });

        setInfos(updatedInfos);
    };

  return (
    <MainWrapper className="rounded-md border border-border overflow-hidden">
        <div className="w-full py-3 px-5 bg-[#e5e5e5] shrink-0 flex items-center justify-between">
            <p className="font-semibold">İlan Bilgileri</p>
        </div>
        <div className='w-full h-full p-3 lg:p-5 overflow-y-auto'>
            {
                infos.map((v, i) => (
                    <div key={i} className='flex items-center gap-3 justify-between border-b border-border py-2'>
                        <div className='flex items-center gap-3 text-third-dark font-semibold text-xs'>
                            <div className='w-4 h-4 lg:w-6 lg:h-6 shrink-0'>
                                {
                                    v.typeValue.icon && (
                                        <Image
                                            src={`${API_URL}/${v.typeValue.icon}`}
                                        />
                                    )
                                }
                            </div>
                            <p>{v.typeValue.name}</p>
                        </div>

                        <div className='w-24'>
                            <Input
                                placeholder='Değer girin...'
                                className='placeholder:text-xs text-center'
                                value={v.value}
                                onChange={(e) => handleInputChange(e, i)}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    </MainWrapper>
  )
}

export default InfosWrapper