import { Hourglass } from "lucide-react"

const LoadingScreen = () => {
  return (
    <div className='bg-first text-white inset-0 fixed z-50 flex items-center justify-center'>
      <Hourglass size={50} className='animate-spin text-primary'/>
    </div>
  )
}

export default LoadingScreen